@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 0.2em;
  height: 0.2em;
  border-radius: 0.3em;
  background: white;
}

::-webkit-scrollbar-button {
  background: #67c748;
  border-radius: 0.3em;
}

::-webkit-scrollbar:horizontal {
  background: white;
}

::-webkit-scrollbar-track-piece {
  background: #eee;
  border-radius: 0.3em;
}

::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 0.3em;
}
​ #components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  background: white;
}

.my-drawer {
  width: 50%;
}
